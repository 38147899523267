<template>
  <div
    class="card card-custom card-shadowless gutter-b card-stretch card-shadowless p-0"
  >
    <!--begin::Nav Tabs-->
    <ul
      class="dashboard-tabs nav nav-pills row row-paddingless m-0 p-0 flex-column flex-sm-row"
      role="tablist"
    >
      <li
        v-for="(item, index) in list"
        :key="index"
        class="nav-item  d-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0 "
      >
        <div class="new" v-if="item.new">Novo</div>
        <router-link
          class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center"
          data-toggle="pill"
          :class="checkActive(item.entity)"
          :to="item.link"
        >
          <span class="nav-icon py-2 w-auto">
            <span class="svg-icon svg-icon-3x">
              <inline-svg :src="$t(`ENTITY.${item.entity}.ICON`)" />
            </span>
          </span>
          <span
            class="nav-text font-size-lg py-2 font-weight-bolder text-center"
            >{{ $t(`ENTITY.${item.entity}.TITLE`) }}</span
          >
        </router-link>
      </li>
    </ul>
    <!--end::Nav Tabs-->
    <!--begin::Nav Content-->
    <div class="tab-content m-0 p-0">
      <div
        class="tab-pane active"
        id="forms_widget_tab_1"
        role="tabpanel"
      ></div>
      <div class="tab-pane" id="forms_widget_tab_2" role="tabpanel"></div>
      <div class="tab-pane" id="forms_widget_tab_3" role="tabpanel"></div>
      <div class="tab-pane" id="forms_widget_tab_4" role="tabpanel"></div>
      <div class="tab-pane" id="forms_widget_tab_6" role="tabpanel"></div>
    </div>
    <!--end::Nav Content-->
  </div>
</template>

<script>
export default {
  name: "Sessions",
  methods: {
    checkActive(entity) {
      return entity === this.routeActive ? "active" : "";
    }
  },
  data() {
    return {};
  },
  computed: {
    list() {
      return this.$t("MENU.LIST");
    },
    routeActive() {
      return this.$route.meta.entity || "DASHBOARD";
    }
  }
};
</script>
<style scoped>
.new {
  z-index: 9;
  border-radius: 10px;
  padding: 8px;
  color: #fff !important;
  background: #1BC5BD;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
